import React, { useEffect, useMemo, useRef } from "react";
import { useMedia } from "react-recipes";
import styled from "styled-components";
import { BusinessInquiry } from "../BusinessInquiry";
import Grid from "../Case/Grid";
import GridItem from "../Case/GridItem";
import Bannerlogo from "../Case/Motion/Bannerlogo";
import AnimTextPerline from "../Case/Motion/Textperline";
import Paragraph5 from "../Case/Text/Paragraph5";
import pxToRem from "../Utils/pxToRem";
import Story from "./Story";
import Marquee from "react-fast-marquee";

import { gsap, useGSAP } from "../../gsap";
import { AwardList } from "../Awards";
import { Projects } from "../../worksData";
import { useForceUpdate, useInView } from "framer-motion";

const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;

  .businessinquiry {
    font-size: ${pxToRem(17)};
    display: flex;
    align-items: center;
    gap: ${pxToRem(11)};

    @media screen and (max-width: 620px) {
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      position: relative;
      padding-left: 15px;
      gap: ${pxToRem(10)};
    }

    .businessinquiry-wrapper {
      display: flex;
      gap: ${pxToRem(10)};

      @media screen and (max-width: 620px) {
        flex-direction: column;
        gap: 0;
      }
    }

    .businessinquiry-flex {
      gap: ${pxToRem(10)};

      @media screen and (max-width: 620px) {
        align-items: flex-start;
        flex-direction: column;
      }
    }

    .businessinquiry-cta {
      display: flex;
      gap: ${pxToRem(10)};
    }

    .businessinquiry-indicator {
      width: ${pxToRem(9.4)};
      height: ${pxToRem(9.4)};

      @media screen and (max-width: 620px) {
        position: absolute;
        margin-left: -15px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, 125%);
        margin-top: 1.1em;
      }
    }

    .businessinquiry-text {
      font-size: ${pxToRem(17)};
      letter-spacing: 0.05em;
      line-height: 1.6;
    }

    .businessinquiry-badge {
      font-size: ${pxToRem(12)};
      font-weight: 900;
      letter-spacing: 0.05em;
      padding: 3px 5px;
    }

    .businessinquiry-book {
      font-size: ${pxToRem(15)};
      text-transform: uppercase;
      letter-spacing: 0.08em;
      line-height: 1.5;
    }
  }
`;

const Container = styled.div`
  padding: 0 calc(var(--case-container) * ${pxToRem(1)});
  box-sizing: border-box;

  @media screen and (max-width: 620px) {
    max-width: 100%;
    padding: 0 ${pxToRem(40)};
  }
`;

const LogosContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: clamp(14px, calc(100vw / 1920 * 56), 56px);
  margin-top: ${pxToRem(40)};

  @media screen and (max-width: 639px) {
    svg:nth-child(3) {
      width: 72%;
    }
  }

  @media screen and (min-width: 640px) {
    display: block;
    margin-top: ${pxToRem(52)};

    svg {
      min-height: ${pxToRem(39)};
      width: auto;
      margin: 0 ${pxToRem(28)};
    }
  }
`;

const AwardsContainer = styled.div`
  margin-top: ${pxToRem(72)};
  margin-right: -${pxToRem(15)};
  margin-left: -${pxToRem(15)};

  @media screen and (min-width: 640px) {
    margin-top: ${pxToRem(72)};
    margin-right: 0;
    margin-left: 0;
  }
`;

const Item = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  margin-left: auto;
  margin-right: auto;

  &:first-of-type {
    // margin-top: ${pxToRem(500)};

    @media screen and (max-width: 620px) {
      // margin-top: ${pxToRem(160)};
      padding-bottom: ${pxToRem(350)} !important;
    }
  }

  &:not(:last-of-type) {
    padding-bottom: ${pxToRem(380)};

    @media screen and (max-width: 620px) {
      padding-bottom: ${pxToRem(160)};
    }
  }

  a {
    color: inherit;
  }
`;

const Title = styled.h3`
  font-family: "Eurostile Next LT Pro Light Ext", sans-serif;
  font-weight: 300;
  font-size: ${pxToRem(130)};
  letter-spacing: -0.02em;
  line-height: 0.8;
  text-align: center;
  white-space: nowrap;

  @media screen and (max-width: 620px) {
    font-size: ${pxToRem(74)};
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Subtitle = styled.h4`
  font-family: "Suisse Intl", sans-serif;
  font-weight: 300;
  font-size: ${pxToRem(20)};
  letter-spacing: 0.07em;
  line-height: normal;
  text-align: center;
  text-transform: uppercase;
  margin-top: ${pxToRem(50)};
  margin-bottom: ${pxToRem(150)};
  white-space: nowrap;

  @media screen and (max-width: 620px) {
    font-size: ${pxToRem(16)};
    margin-top: ${pxToRem(30)};
    margin-bottom: ${pxToRem(80)};
  }

  .bracket {
    position: relative;
    top: 1px;
  }

  .collapse-text {
    width: 0px;
    transform-origin: center;
    display: inline-block;

    .masking-text {
      display: inline-flex;
    }

    .line {
      transform: translateY(101%);
      display: inline-block;
    }
  }
`;

const Value = (props) => {
  const isMobile = useMedia(["(max-width: 640px)"], [true], false);
  const isTablet = useMedia(["(max-width: 1200px)"], [true], false);

  const el = useRef();
  const itemRefs = useRef([]);
  const logoContainer = useRef();

  const logoContainerInView = useInView(logoContainer);

  const [forceRender] = useForceUpdate();

  const addItemRef = (el, index) => {
    itemRefs.current[index] = el;
  };

  const awardListItems = useMemo(() => (
    Projects.filter((project) => project.awards?.length)
  ), []);

  useGSAP(
    () => {
      // if (isMobile) return;

      gsap.utils.toArray(itemRefs.current).forEach((el, i) => {
        const collapseText = el.querySelector(".collapse-text");
        const line = collapseText.querySelector(".line");

        const tlCollapseText = gsap.timeline({
          scrollTrigger: {
            trigger: el,
            start: "top bottom",
          },
        });

        tlCollapseText.to(collapseText, {
          width: "auto",
          duration: 0.8,
          ease: "circ.inOut",
        });

        tlCollapseText.to(
          line,
          {
            y: 2,
            duration: 0.8,
          },
          "-=0.1"
        );
      });
    },
    {
      dependencies: [isMobile],
      scope: el,
    }
  );

  useEffect(() => {
    // Potentially fixes Safari rendering issue
    if (logoContainerInView) {
      forceRender();
    }

    if (logoContainerInView) {
      const e = new Event('resize', { cancelable: false, bubbles: false });
      window.dispatchEvent(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logoContainerInView]);

  return (
    <Wrapper ref={el}>
      {props.data.map((item, index) => (
        <React.Fragment key={index}>
          <Item>
            <Container>
              <div>
                <AnimTextPerline>
                  <Title dangerouslySetInnerHTML={{ __html: item.title }} />
                </AnimTextPerline>
                <Subtitle ref={(el) => addItemRef(el, index)}>
                  <span className="bracket">(&nbsp;</span>
                  <span className="collapse-text">
                    <span className="masking-text">
                      <span className="line">{item.subtitle}</span>
                    </span>
                  </span>
                  <span className="bracket">&nbsp;)</span>
                </Subtitle>

                {!item.descriptionCenter ? (
                  <Grid>
                    <GridItem start={6} end={13}>
                      <AnimTextPerline>
                        {item.descriptionLeft.map((text, index2) => (
                          <Paragraph5
                            key={index2}
                            margin={`0 0 ${pxToRem(25)} 0`}
                            color="rgba(209, 209, 210, 0.8)"
                          >
                            <span dangerouslySetInnerHTML={{ __html: text }} />
                          </Paragraph5>
                        ))}
                      </AnimTextPerline>
                    </GridItem>
                    <GridItem
                      start={13}
                      end={20}
                      padding={isMobile ? `0` : `0 ${pxToRem(39)} 0 0`}
                    >
                      <AnimTextPerline>
                        {item.descriptionRight.map((text, index3) => (
                          <Paragraph5
                            key={index3}
                            margin={`0 0 ${pxToRem(25)} 0`}
                            color="rgba(209, 209, 210, 0.8)"
                          >
                            {text
                              .split("[banner]")
                              .map((part, index, array) => (
                                <React.Fragment key={index}>
                                  <span
                                    dangerouslySetInnerHTML={{ __html: part }}
                                  />
                                  {index < array.length - 1 && ( // Only add the BannerLogo if it's not the last part
                                    <Bannerlogo
                                      text="Petar Stojakovic"
                                      width={
                                        isMobile ? pxToRem(295) : isTablet ? `${pxToRem(480)}` : `clamp(300px, ${pxToRem(397)}, ${pxToRem(397)})`
                                      }
                                      height="auto"
                                      top={
                                        isMobile ? pxToRem(-145) : isTablet ? "100%" : pxToRem(-70)
                                      }
                                      left={
                                        isMobile ? pxToRem(0) : isTablet ? "80%" : pxToRem(-155)
                                      }
                                      src={`/img/petar.png`}
                                      bannerColor="#3D3A3F"
                                      closeColor="#3D3A3F"
                                      closeLine="#FFF"
                                      biography={true}
                                    />
                                  )}
                                </React.Fragment>
                              ))}
                          </Paragraph5>
                        ))}
                      </AnimTextPerline>
                    </GridItem>
                  </Grid>
                ) : (
                  <Grid>
                    <GridItem start={9} end={17}>
                      <AnimTextPerline>
                        {item.descriptionCenter.map((text, index4) => (
                          <Paragraph5
                            key={index4}
                            margin={`0 0 ${pxToRem(25)} 0`}
                            color="#d1d1d2"
                          >
                            {text}
                          </Paragraph5>
                        ))}
                      </AnimTextPerline>

                      <div className="businessinquiry">
                        <BusinessInquiry
                          className="businessinquiry-flex"
                          isBook={true}
                          delay={3}
                        />
                      </div>
                    </GridItem>
                  </Grid>
                )}
              </div>

              {item?.logos && (
                <>
                  <Grid>
                    <GridItem
                      start={6}
                      startM={2}
                      end={20}
                      endM={6}
                      padding={isMobile ? `0` : `0 ${pxToRem(39)} 0 0`}
                    >
                      <LogosContainer ref={logoContainer}>
                        {!isMobile && (
                          <Marquee>
                            {item.logos.map((Logo, idx) => (
                              <Logo key={idx} />
                            ))}
                          </Marquee>
                        )}

                        {isMobile && (
                          item.logos.map((Logo, idx) => (
                            <Logo key={idx} />
                          ))
                        )}
                      </LogosContainer>
                    </GridItem>

                  </Grid>

                  <Grid>
                    <GridItem
                      start={6}
                      startM={1}
                      end={20}
                      endM={8}
                      padding={isMobile ? `0` : `0 ${pxToRem(39)} 0 0`}
                    >
                      <AwardsContainer>
                        <AwardList items={awardListItems} />
                      </AwardsContainer>
                    </GridItem>
                  </Grid>
                </>
              )}
            </Container>
          </Item>

          {index === 0 && <Story />}
        </React.Fragment>
      ))}
    </Wrapper>
  );
};

export default Value;
