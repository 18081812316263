import classNames from 'classnames';
import { useIsPresent } from 'framer-motion';
import React from 'react';
import { Durations, Easings } from '../../animation';
import { useNormalizeValue } from '../../hooks';
import { ClipPathMask } from '../ClipPathMask';
import { MaskedReveal } from '../Motion';
import { MotionFlex } from '../MotionFlex';
// import { MotionIcon } from '../icons';
import classes from './Button.module.scss';
import { useButton } from './hooks';
import { motion } from 'framer-motion';

/**
 * @typedef {'default'} ButtonVariant
 */

/**
 * @typedef {object} ButtonPartialProps
 * @property {boolean} [asLink=false]
 * @property {[number, number] | number} [delay]
 * @property {string} [href]
 * @property {import('react').ReactNode} [icon]
 * @property {boolean} [internal=false]
 * @property {boolean} noAnimation
 * @property {'none'|'all'} [pointerEvents='all']
 * @property {import('react').AnchorHTMLAttributes['target']} [target]
 * @property {ButtonVariant} [variant]
 */

/**
 * @typedef {import('react').ButtonHTMLAttributes & ButtonPartialProps} ButtonProps
 */

/** @type {import('framer-motion').Variants} */
const IconVariants = {
  enter: ([delay]) => ({
    scale: 1,
    y: 0,
    transition: {
      delay,
      duration: 0.48,
      ease: Easings.easeOutCubic
    }
  }),
  exit: ([, delay]) => ({
    scale: 0,
    y: '50%',
    transition: {
      delay,
      duration: 0.48,
      ease: Easings.easeInCubic
    }
  })
};

/** @type {import('framer-motion').Variants} */
const BodyVariants = {
  enter: {
    y: 0
  },
  exit: {
    y: '64%',
    // y: 8,
    transition: {
      delay: 0.32,
      duration: 0.64,
      ease: Easings.easeInCubic
    }
  }
}

/**
 * @type {ReturnType<typeof import('react').forwardRef<HTMLElement, ButtonProps>>}
 */
export const Button = React.forwardRef((props, ref) => {
  const {
    asLink,
    children,
    delay = 0,
    href,
    internal,
    noAnimation = false,
    pointerEvents = 'all',
    target,
    variant = 'default',
    onClick,
    ...rest
  } = props;

  const isPresent = useIsPresent();
  const isInteractive = pointerEvents !== 'none' && isPresent;
  const [delayEnter, delayExit] = useNormalizeValue(delay, false);

  const { attributes: { el, ...attributes }, bind } = useButton({
    asLink,
    href,
    interactive: isInteractive,
    internal,
    target,
    eventHandlers: { onClick }
  });

  return (
    <ClipPathMask
      {...attributes}
      {...rest}
      as={el}
      {...bind}
      ref={ref}
      className={classNames([
        classes.root,
        {
          [classes.interactive]: isInteractive,
          [classes[variant]]: !!variant,
        },
        props.className,
      ])}
      data-interactive={isInteractive ? '' : undefined}
      delay={[delayEnter, delayExit]}
      duration={[Durations.base, 1.2]}
      maskDirection={noAnimation ? 'none' : 'bottom'}
    >
      <MotionFlex
        alignItems="center"
        exit="exit"
        initial="enter"
        variants={BodyVariants}
      >
        <div className={classes.text} data-textbutton-text>
          {!noAnimation && (
            <MaskedReveal
              className={classes.innerText}
              delay={[delayEnter + 0.32, delayExit + 0.40]}
              duration={0.72}
            >
              {children}
            </MaskedReveal>
          )}

          {noAnimation && children}
        </div>


        <motion.div
          animate="enter"
          className={classes.icon}
          custom={[delayEnter + 0.64, delayExit + 0.56]}
          exit="exit"
          initial="exit"
          variants={noAnimation ? null : IconVariants}
        >
          <div
            style={{
              height: '100%',
              backgroundColor: '#F75703',
              borderRadius: '50%'
            }}
          />
        </motion.div>
        {/* <MotionIcon
          initial="exit"
          exit="exit"
          animate="enter"
          custom={[delayEnter + 0.64, delayExit + 0.56]}
          className={classes.icon}
          color="#F75703"
          type="circle"
          variants={noAnimation ? null : IconVariants}
          style={{ transformOrigin: 'center' }}
        /> */}
      </MotionFlex>
    </ClipPathMask>
  );
});

Button.displayName = 'Button';
